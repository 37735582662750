import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/Common/NicheCard.module.scss";

const NicheCard = (props) => {
  const { image, title, description, hoverColor, key } = props;
  return (
    <Box
      className={styles.nicheCardWrapper}
      sx={{
        "&:hover": {
          backgroundColor: `${hoverColor}`,
        },
      }}
      key={key}
    >
      <Box className={styles.nicheImage}>
        <img loading="lazy" src={image} alt="" />
      </Box>
      <Box>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box>
        <Typography variantMapping="p">{description}</Typography>
      </Box>
    </Box>
  );
};

export default NicheCard;
