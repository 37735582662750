import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import * as styles from "../../styles/Common/BlogCard.module.scss";

const BlogCard = (props) => {
  console.log("🚀 ~ file: BlogCard.js:6 ~ BlogCard ~ props:", props);
  const {
    text,
    para,
    image,
    background,
    hoverColor,
    categories,
    categoryBack,
  } = props;
  return (
    <Box
      className={styles.blogCardWrapper}
      sx={{
        backgroundColor: `${background}`,
        "&:hover": {
          backgroundColor: `${hoverColor}`,
        },
      }}
    >
      <Box className={styles.blogCardImage}>
        <img loading="lazy" src={image} alt="blog" />
      </Box>
      <Box className={styles.blogCardCategory}>
        {categories.map((content) => {
          return (
            <Typography
              paragraph={true}
              sx={{
                backgroundColor: `${categoryBack}`,
              }}
            >
              {content}
            </Typography>
          );
        })}
      </Box>
      <Box className={styles.blogCardHeading}>
        <Typography variant="h6">{text}</Typography>
      </Box>
      <Box className={styles.blogCardPara}>
        <Typography paragraph={true}>{para}</Typography>
      </Box>
      <Divider
        sx={{
          borderColor: `${categoryBack}`,
          minWidth: "100%",
          maxHeight: "2px",
          margin: "15px 0px",
          opacity: "40%",
        }}
      />
      <Box className={styles.blogCardAuthor}>
        <Typography paragraph={true}>by Olivia Rhye </Typography>
        <CircleIcon
          sx={{
            fill: `${categoryBack}`,
            fontSize: "10px",
            opacity: "0.5",
          }}
        />
        <Typography paragraph={true}>Mar 7, 2023</Typography>
      </Box>
    </Box>
  );
};

export default BlogCard;
