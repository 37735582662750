import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/Common/OffersCard.module.scss";

const OffersCard = (props) => {
  return (
    <Box
      className={styles.offersCard}
      sx={{
        border: `${props.border}`,
        maxWidth: `${props.width}`,
        minHeight: `${props.height}`,
        "&:hover": {
          backgroundColor: `${props.hoverColor}`,
        },
      }}
    >
      <Box
        className={styles.offerCardImage}
        sx={{
          border: `${props.border}`,
        }}
      >
        <img loading="lazy" src={props.image} alt="" />
      </Box>
      <Box className={styles.offerCardContent}>
        <Typography variantMapping="p">{props.text}</Typography>
      </Box>
    </Box>
  );
};

export default OffersCard;
