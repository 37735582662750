import React from "react";
import { Container, Box, Typography } from "@mui/material";

import * as styles from "../../styles/HomePage/Quickxi.module.scss";

const Quickxi = () => {
  return (
    <Container>
      <Box className={styles.quickWrapper}>
        <Box className={styles.quickContent}>
          <Typography variant="h2">Sell everywhere with Quickxi!</Typography>
          <Typography variantMapping="p">
            Our one feature-rich platform with exclusive point of sale features
            to help you grow, run, and operate your business effectively.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Quickxi;
