// Images
import BlogImageOne from "../images/blogImageOne.svg";
import BlogImageTwo from "../images/blogImageTwo.svg";
import BlogImageThree from "../images/blogImageThree.svg";

const cardContent = [
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#EAE7FD",
    hoverColor: "#D8D2FF",
    categories: ["Marketing", "Design"],
    categoryBack: "#837BFC",
  },
  {
    title: "Benefits of Choosing Off the Shelf Software",
    para: "Now is the time to switch from a traditional communication platform to a swift, and seamless network. Enabling you to build a connected and flexible",
    image: BlogImageTwo,
    backColor: "#FFEEEF",
    hoverColor: "#FFDEE0",
    categories: ["Software", "Game Development"],
    categoryBack: "#E94445",
  },
  {
    title: "5 Reasons Your Restaurant Should Have an App",
    para: "As we are well aware, when COVID-19 grasped the world it crippled the infrastructure and halted its operating system. From there, a seismic shift began",
    image: BlogImageThree,
    backColor: "#E2FBF4",
    hoverColor: "#BFFCEB",
    categories: ["Customer Service", "Cultural Arts"],
    categoryBack: "#24D18E",
  },
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#D4F5FF",
    hoverColor: "#B8EEFF",
    categories: ["Marketing", "Design"],
    categoryBack: "#1DBDEF",
  },
  {
    title: "Benefits of Choosing Off the Shelf Software",
    para: "Now is the time to switch from a traditional communication platform to a swift, and seamless network. Enabling you to build a connected and flexible",
    image: BlogImageTwo,
    backColor: "#FFEEEF",
    hoverColor: "#FFDEE0",
    categories: ["Software", "Game Development"],
    categoryBack: "#E94445",
  },
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#EAE7FD",
    hoverColor: "#D8D2FF",
    categories: ["Marketing", "Design"],
    categoryBack: "#837BFC",
  },
  {
    title: "5 Reasons Your Restaurant Should Have an App",
    para: "As we are well aware, when COVID-19 grasped the world it crippled the infrastructure and halted its operating system. From there, a seismic shift began",
    image: BlogImageThree,
    backColor: "#E2FBF4",
    hoverColor: "#BFFCEB",
    categories: ["Customer Service", "Cultural Arts"],
    categoryBack: "#24D18E",
  },
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#D4F5FF",
    hoverColor: "#B8EEFF",
    categories: ["Marketing", "Design"],
    categoryBack: "#1DBDEF",
  },
  {
    title: "5 Reasons Your Restaurant Should Have an App",
    para: "As we are well aware, when COVID-19 grasped the world it crippled the infrastructure and halted its operating system. From there, a seismic shift began",
    image: BlogImageThree,
    backColor: "#E2FBF4",
    hoverColor: "#BFFCEB",
    categories: ["Customer Service", "Cultural Arts"],
    categoryBack: "#24D18E",
  },
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#EAE7FD",
    hoverColor: "#D8D2FF",
    categories: ["Marketing", "Design"],
    categoryBack: "#837BFC",
  },
  {
    title: "Benefits of Choosing Off the Shelf Software",
    para: "Now is the time to switch from a traditional communication platform to a swift, and seamless network. Enabling you to build a connected and flexible",
    image: BlogImageTwo,
    backColor: "#FFEEEF",
    hoverColor: "#FFDEE0",
    categories: ["Software", "Game Development"],
    categoryBack: "#E94445",
  },
  {
    title: "e-Commerce Mobile App: Why Does your Business need it?",
    para: "Digital transformation has become the table stakes in recent times and makes business processes a lot easier than the labor exertion we expected previously. Find",
    image: BlogImageOne,
    backColor: "#D4F5FF",
    hoverColor: "#B8EEFF",
    categories: ["Marketing", "Design"],
    categoryBack: "#1DBDEF",
  },
];

export default cardContent;
