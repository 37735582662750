import React from "react";
import { Box, Container, Typography } from "@mui/material";
import NicheCard from "../Common/NicheCard";
import Restaurant from "../../images/Restaurant.svg";
import Groceries from "../../images/Groceries.svg";
import Store from "../../images/Stores.svg";
import Shop from "../../images/OnlineShop.svg";
import * as styles from "../../styles/HomePage/Niches.module.scss";

const Niches = () => {
  const content = [
    {
      image: Restaurant,
      title: "Restaurants",
      description:
        "Create any design you want with our online shop builder, we have all the tools you need.",
      hoverColor: "#EAE7FD",
    },
    {
      image: Groceries,
      title: "Groceries",
      description:
        "Quickly get up and start selling with an eCommerce website builder that’ll optimize your sales.",
      hoverColor: "#FFEEEF",
    },
    {
      image: Shop,
      title: "Online Shop",
      description:
        "Full-featured online store structured for you along with powerful easy-to-use tools.",
      hoverColor: "#D4F5FF",
    },
    {
      image: Store,
      title: "On Demand Stores",
      description:
        "Bring your vision to life with our easy to use store builder. No coding skills required, just your idea and creativity.",
      hoverColor: "#E2FBF4",
    },
  ];
  return (
    <section className={styles.nicheBG}>
      <Container>
        <Box className={styles.nicheContent}>
          <Typography variant="h2">
            Four different niches to explore!
          </Typography>
          <Typography paragraph={true}>
            Quickxi’s main goal is to offer innovative impactful solutions, for
            small-mid level Businesses
          </Typography>
        </Box>
        <Box className={styles.cardsWrapper}>
          {content.map((content, key) => (
            <NicheCard
              image={content.image}
              title={content.title}
              description={content.description}
              hoverColor={content.hoverColor}
              key={key}
            />
          ))}
        </Box>
      </Container>
    </section>
  );
};

export default Niches;
