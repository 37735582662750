import * as React from "react";
import Layout from "../Components/Common/Layout";
import HeroSection from "../Components/Common/HeroSection";
import EmailForm from "../Components/Common/EmailForm";
import Niches from "../Components/HomePage/Niches";
import MobileApp from "../Components/HomePage/MobileApp";
import Feature from "../Components/Common/Feature";
import Quickxi from "../Components/HomePage/Quickxi";
import Offers from "../Components/HomePage/Offers";
import Seo from "../Components/seo";
import { Helmet } from "react-helmet";
// import { graphql } from "gatsby";
import BlogSection from "../Components/HomePage/BlogSection";
import Questions from "../Components/Common/Questions";

// Images
import Platform from "../images/Platform.svg";
import Branding from "../images/Branding.png";
import Payment from "../images/Payment.png";
import Notification from "../images/Notification.svg";
import HeroImage from "../images/HeroImage.svg";

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="index, follow" />
      <meta name="robots" content="max-image-preview:standard" />
    </Helmet>
    <Seo
      title="Online Store Builder - Grow your business with Quickxi"
      description="Online shop builder for restaurants, grocery, online, and on-demand apps. With built-in features set up your online store in just a few minutes."
      tag="index"
      pageUrl=""
    />
    <HeroSection
      title="Start selling online with highly customized pre-made web/apps"
      subTitle="Quickxi is an online platform builder integrated with accomplished features to set up a store in just a few minutes."
      buttonText="Join Now"
      image={HeroImage}
    />
    <Niches />
    <MobileApp />
    <Feature
      title="Cross platform"
      descp="Applications at disposal for great user experience available on both iOS and Android platforms. Effortless developing service on both OS, Quickxi makes it convenient for users."
      image={Platform}
    />
    <Feature
      title="Customized branding"
      descp="Enjoy the freedom to customize web/apps your way and give an enhanced outlook. Add features and increase functionality of your store with Quickxi special features."
      image={Branding}
      direction="row-reverse"
    />
    <Feature
      title="Secure payment"
      descp="Secure payment transaction and functionality for a guaranteed coherent user experience. Quickxi empowers a secure, faster, and more reliable method of payment."
      image={Payment}
    />
    <Feature
      title="Push notifications"
      descp="Promote real-time offers via push notifications. You can go creative with catchy taglines. It makes it easier for businesses to send flash sales, discounts, or coupons invitations."
      image={Notification}
      direction="row-reverse"
    />
    <Quickxi />
    <Offers />
    <BlogSection />
    <Questions />
    <EmailForm />
    <script type="application/ld+json">
      {`{
                "@context": "https://schema.org/",
                "@type": "FAQPage",
                "mainEntity": [
                  {
                    "@type": "Question",
                    "name": "What is the purchase process?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can email us at info@quickxi.com for a live demo and share your requirements to get a quote. After your payment confirmation, our team will start with the development tweaks (if any)."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "What are my payment options?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can pay via Debit/credit card, Paypal, or bank transfer. We do not accept cheques."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "Will I be charged for the mobile application only once?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "Yes, you will be charged for the application only once. There are no recurring payments. Please email us at info@quickxi.com for a quote and related queries."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "In case of queries and support, where can I reach you?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "You can email us at info@quickxi.com, and one of our representatives will get back to you within 24 hrs."
                    }
                  },
                  {
                    "@type": "Question",
                    "name": "How to get started with Quickxi?",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "As mobile users continue to rise, companies are creating creative mobile applications to attract their target consumers. We have streamlined your app creation process by providing you with pre-made apps. Choose your mobile app Request us a live demo  Share your requirements  Get a quote  Let's begin!"
                    }
                  }
                ]
              }`}
    </script>
    {/* Schema added */}
    <script type="application/ld+json">
      {`{
    "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Quickxi",
  "url": "https://quickxi.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "{search_term_string}",
    "query-input": "required name=search_term_string"
  }
  }`}
    </script>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Quickxi",
        "url": "https://quickxi.com/",
        "logo": "https://quickxi.com/static/LogoQImage-5d0c834c1a17e639e08d16cd51e114d8.svg",
        "alternateName": "Quickxi",
        "sameAs": [
          "https://www.facebook.com/quickxi",
          "https://www.linkedin.com/company/quickxi/",
          "https://twitter.com/QuickxiOfficial"
        ],
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": "+1(754) 258-7812",
            "contactType": "customer service",
            "email": "info@quickxi.com",
            "areaServed": "US",
            "availableLanguage": "en"
          }
        ]
      }`}
    </script>
    <script type="application/ld+json">
      {`{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Quickxi",
  "image": "https://quickxi.com/static/5b827b907710441dbd73dfb2dfcc9b7a/6220e/LogoQImage.webp",
  "description": "Online shop builder for restaurants, grocery, online, and on-demand apps. With built-in features set up your online store in just a few minutes.",
  "brand": "Quickxi",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "334"
  }
}`}
    </script>
  </Layout>
);

export default IndexPage;

// export const query = graphql`
//   query Query {
//     allStrapiBlog {
//       nodes {
//         Image {
//           url
//         }
//         Title
//         updatedAt
//         Slug
//         Content
//         metaTitle
//         metaDescription
//       }
//     }
//   }
// `;
