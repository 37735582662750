import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import BlogCard from "../Common/BlogCard";
import cardContent from "../../Data/BlogData";

import * as styles from "../../styles/HomePage/BlogSection.module.scss";
import { Link } from "gatsby";

const BlogSection = () => {
  return (
    <Container>
      <Box className={styles.blogSectionWrapper}>
        <Box className={styles.blogSectionText}>
          <Typography variant="h2">Enjoy our blog treasure</Typography>
          <Typography paragraph={true}>
            The latest and most astounding feature articles about the quickxi
            world, and out.
          </Typography>
        </Box>
        <Box className={styles.cardsWrapper}>
          {cardContent?.slice(0, 3).map((card, index) => {
            return (
              <BlogCard
                text={card.title}
                para={card.para}
                image={card.image}
                background={card.backColor}
                hoverColor={card.hoverColor}
                categories={card.categories}
                categoryBack={card.categoryBack}
                key={index}
              />
            );
            // return <BlogCard blogData={blogData} key={index} />;
          })}
        </Box>
        <Box className={styles.readMoreButton}>
          <Link to="/blog/">
            <Button>Read More</Button>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogSection;
