import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Edit from "../../images/Edit.svg";
import SEO from "../../images/SEO.svg";
import Reach from "../../images/Reach.svg";
import Check from "../../images/Check.svg";
import Notify from "../../images/Notify.svg";
import Payment from "../../images/PayMethods.svg";
import Map from "../../images/Map.svg";
import Growth from "../../images/Growth.svg";
import Selling from "../../images/Selling.svg";
import ECom from "../../images/ECommerce.svg";
import OffersCard from "../Common/OffersCard";

import * as styles from "../../styles/HomePage/Offers.module.scss";

const Offers = () => {
  const cardsContent = [
    {
      text: "Easy-to-edit content management system (CMS)",
      image: Edit,
      border: "1px solid #24D18E",
      hoverColor: "#24D18E33",
    },
    {
      text: "Search engine optimized (SEO) webpage",
      image: SEO,
      border: "1px solid #837BFC",
      hoverColor: "#837BFC33",
    },
    {
      text: "Add or remove branches to increase reach",
      image: Reach,
      border: "1px solid #F8E044",
      hoverColor: "#F8E04433",
    },
    {
      text: "Smooth check-out system for customers",
      image: Check,
      border: "1px solid #6588FA",
      hoverColor: "#6588FA33",
    },
    {
      text: "Push notification for mobile apps and web solutions",
      image: Notify,
      border: "1px solid #FB79AF",
      hoverColor: "#FB79AF33",
    },
    {
      text: "Integrate multiple payment methods for customer",
      image: Payment,
      border: "1px solid #2EC6F6",
      hoverColor: "#2EC6F633",
    },
    {
      text: "Map integration for smooth online shopping",
      image: Map,
      border: "1px solid #24D18E",
      hoverColor: "#24D18E33",
    },
    {
      text: "Scalable infrastructure for business growth",
      image: Growth,
      border: "1px solid #C457FA",
      hoverColor: "#C457FA33",
    },
    {
      text: "Customer support tab for effective online selling and buying",
      image: Selling,
      border: "1px solid #2EC6F6",
      hoverColor: "#2EC6F633",
    },
    {
      text: "Secure, reliable mobile app and website eCommerce solution",
      image: ECom,
      border: "1px solid #F8E044",
      hoverColor: "#F8E04433",
      width: "245px",
    },
  ];

  return (
    <section className={styles.offersWrapper}>
      <Container>
        <Box className={styles.offersFlex}>
          <Box className={styles.offersContent}>
            <Typography variant="h2">Our Featured Offers</Typography>
            <Typography variantMapping="p">
              We always want to provide the best value for you, join us to
              maximize the benefits
            </Typography>
          </Box>
          {cardsContent.map((content, key) => (
            <OffersCard
              text={content.text}
              image={content.image}
              border={content.border}
              hoverColor={content.hoverColor}
              width={content.width}
              height={content.height}
              key={key}
            />
          ))}
        </Box>
      </Container>
    </section>
  );
};

export default Offers;
