import React from 'react'
import { Container, Typography, Box } from '@mui/material'
import MobileAppSlider from './MobileAppSlider'

import * as styles from '../../styles/HomePage/MobileApp.module.scss'

const MobileApp = () => {
  return (
    <Container>
        <Box className={styles.mobileAppWrapper}>
            <Box className={styles.mobileAppHeading}>
                <Typography variant='h2'>
                Super Responsive & Customizable Mobile Applications
                </Typography>
            </Box>
        </Box>
        <Box>
            <MobileAppSlider/>
        </Box>
    </Container>
  )
}

export default MobileApp