import React from "react";
import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Personalised from "../../images/Personalised.svg";
import Quality from "../../images/high-quality.svg";
import GDPR from "../../images/check-list.svg";
import Profit from "../../images/profit.svg";

import * as styles from "../../styles/HomePage/MobileAppSlider.module.scss";

const MobileAppSlider = () => {
  const sliders = [
    {
      title: "PERSONALIZED MOBILE APPLICATIONS",
      descrip:
        "Manage your business on the go with an eCommerce mobile app. Create products list, manage delivery, process payments, and keep track of stats.",
      Image: Personalised,
      altText: "Personalised",
      backColor: "#5D3CEB",
      iconBackColor: "#FFFFFF66",
    },
    {
      title: "HIGH-QUALITY",
      descrip:
        "Quickxi is the best online store builder, offering high quality and purposeful features that are essential for creating a web/app from scratch.",
      Image: Quality,
      altText: "Quality",
      backColor: "#E94445",
      iconBackColor: "#FFFFFF66",
    },
    {
      title: "GDPR COMPLIANCE",
      descrip:
        "Our platform meets all the requirements of handling personal data as defined in the law. So build your online shop carefree.",
      Image: GDPR,
      altText: "GDPR",
      backColor: "#4285F4",
      iconBackColor: "#FFFFFF66",
    },
    {
      title: "COST-EFFICIENT",
      descrip:
        "Quickxi is cost-effective, meaning you can start your dream store by subscribing to the basic package, and upgrade it as you go.",
      Image: Profit,
      altText: "Profit",
      backColor: "#1BBC7E",
      iconBackColor: "#FFFFFF66",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box
      sx={{
        "@media screen and (max-width:768px)": {
          maxHeight: "300px",
        },
      }}
    >
      <Slider {...settings}>
        {sliders.map((slide, key) => (
          <Box
            className={styles.slideDiv}
            key={key}
            sx={{
              backgroundColor: `${slide.backColor}`,
            }}
          >
            <Box className={styles.slideContent}>
              <Box className={styles.slideText}>
                <Typography variant="h3">{slide?.title}</Typography>
                <Typography variantMapping="p">{slide?.descrip}</Typography>
              </Box>
              <Box
                className={styles.slideImage}
                sx={{
                  backgroundColor: `${slide.iconBackColor}`,
                }}
              >
                <img loading="lazy" src={slide.Image} alt={slide.altText} />
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default MobileAppSlider;
